a<template>
  <div :class="{ contain: true, mobile: isMobile }">
    <div class="title mb-5 mt-5">{{ $t("headerUserCenterNavMyLuckyDraw") }}</div>
    <div v-if="empty" class="empty">{{$t('EmptyHere')}}</div>
    <div
      class="list mb-3 d-flex flex-lg-row justify-space-between"
      v-for="item in list"
      :key="item.id"
      v-scroll="onScroll"
    >
      <div class="price mr-lg-16" style="width: 25%">
        <div class="pricetit">{{ item.activityName }}</div>
        <div class="d-flex flex-lg-row">
          <img
            class="priceicon"
            :src="
                require('@/assets/icons/' +
                  getChainByName(item.coinDenom)+
                  '.png')
          "
            width="22px"
            height="22px"
            alt=""
          />
         
          <div class="pricenum">{{ item.prizePool }}</div>
        </div>
      </div>
      <div class="num mr-lg-n16 d-flex flex-row"  @click="toLottery(item.id)" style="width: 20%" >
        <img
          v-if="item.luck"
          class="pool"
          src="@/assets/image/icon_pool.png"
          alt=""
          width="37px"
          height="34px"
        />
      
        <!-- <div
          style="width: 37px; height: 34px; margin-right: 14px"
          v-if="item.isLucky == false"
        ></div> -->
        <div class="d-flex flex-row " style="font-size:15px">
          <div>{{ $t("myLotteries") }}：{{ item.joinNumber }} </div>
        <img @click="toLotteries(item.id)" :src="rightIcon" width="31px"
          height="30px" style="margin-top:-4px"/>
        </div>
        
      </div>
      <div class="time mr-lg-n16 d-flex flex-lg-row mt-4" style="width: 19%">
        <img
          src="@/assets/image/pool_chianlink.png"
          alt=""
          width="31px"
          height="30px"
          
        />
        <div class="ml-2" style="font-size:15px; font-weight: bold;" >{{ item.endTime }}</div>
      </div>
      <div class="link d-flex flex-row" style="width: 5% " v-if="item.txid">
        <img src="@/assets/image/icon_link.png" width="23px" height="23px" style=" cursor: pointer;" @click="copyChainLink(item.txid)"/>
        <img
          src="@/assets/image/icon_share1.png"
          class="ml-3"
          width="23px"
          height="23px"
          style=" cursor: pointer;"
          @click="shareBtn(item.id)"
        />
      </div >
      <div v-if="!item.txid"
      class="link d-flex flex-row"
          style="width: 5%">
        
      </div>

      
      <div class="share"  v-if="share_id==item.id "  >
            <div
             style=" cursor: pointer;"
              class="d-flex flex-row mt-lg-5 mt-5 pointer"
              :class="{ click: isCopy }"
              @click="share('copyLink', 'https://serviceiris.upticknft.com/sharing/'+item.id+'/lucky.png','','https://bscscan.com/tx/'+item.txid)"
            >
              <img
                class="mx-5"
                src="@/assets/icons/icon_IPFS.png"
                alt="Copy Link"
              />
              <span class="ml-0">
                {{ $t("carddetailShareCopyLink") }}
              </span>
            </div>
            <!-- <div class="d-flex flex-row mt-lg-6 mt-6 pointer " @click="toTweet"> -->
            <div
             style=" cursor: pointer;"
              class="d-flex flex-row mt-lg-6 mt-6 pointer"
              @click="share('twitter', 'https://serviceiris.upticknft.com/sharing/'+item.id+'/lucky.png','','https://bscscan.com/tx/'+item.txid)"
            >
              <img
                class="mx-5"
                src="@/assets/icons/icon_twitter.png"
                alt="Tweet"
              />
              <span class="ml-0">Twitter</span>
            </div>
            <div
             style=" cursor: pointer;"
              class="d-flex flex-row mt-lg-6 mb-lg-4 mt-6 mb-4 pointer"
              @click="share('facebook', sharePath)"
            >
              <v-icon class="mx-lg-5 ml-5 icon" color="primary">
                mdi-facebook
              </v-icon>
              <span class="ml-0 pr-7">Facebook</span>
            </div>

          </div>
    </div>
    <loading :isShowLoading="isShowLoading" ></loading>
    <div v-if="openlottery" style="position: fixed; right: 20px;top:0px; bottom: -20px; z-index: 99999">
      <lottery  ref="lottery" v-if="openlottery" @getOpen="OpenLot" :luckyID ="luckyId" ></lottery>
		</div>
    
  </div>
</template>

<script>
import api from "@/api";
  import Loading from "@/components/loading.vue";
import { share } from "@/utils";
import rightIcon from "@/assets/icons/icon_r.png";
import lottery from "@/components/popup/lottery.vue";
export default {
  components: { Loading,lottery},
  data: () => ({
    list: [
      // {
      //     id:1,
      //     title:'#10',
      //     price:'100.236',
      //     num:'3',
      //     time:'2021.09.12  10:00:00',
      //     isLucky:false,
      // },
      // {
      //     id:2,
      //     title:'#20',
      //     price:'100.246835451936',
      //     num:'8',
      //     time:'2029.09.12  10:00:00',
      //     isLucky:true,
      // },
      // {
      //     id:3,
      //     title:'#130',
      //     price:'1009846512346.236',
      //     num:'19',
      //     time:'2021.02.12  10:00:00',
      //     isLucky:true,
      // },
    ],
    rightIcon,
    pageNumber: 0,
    pageSize: 10,
    openShare: false,
    share_id: 0,
    isCopy: false,
    isShowLoading: false,
    isLoading:false,
    openlottery:false,
    luckyId:'',
    empty: false,
  }),
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  async mounted() {
    // let params = {
    //   activityId:248,
    //   owner: "iaa1dqfet703fj838m9u3yq68h2rczt8fr3e57agal",
    
    // }
    // let res = await api.home.joinInfoByOwner(params)
    // console.log("rrrrrrrrrrrr")
    // console.log(res)
   
   
    await this.getlist();
  },
  methods: {
    copyChainLink( txid){
     
          let url = 'https://bscscan.com/tx/'+txid
      window.open(url);
      

    
    },
    
    //打开弹框
      toLottery(id){
        this.luckyId = id
          this.openlottery = true
          this.$refs.lottery.init(this.luckyId);
          //setTimeout(() => { window.eventBus.$emit("ViewMessage", true) }, 1000);
          
      },
			//弹框关闭后获取open值
			OpenLot(e) {
        this.openlottery = e;
        //window.eventBus.$emit("ViewMessage", true);
			},
    share,
    async getlist() {
      if(this.isLoading)
      {
        return;
      }
      this.isLoading = true
      this.isShowLoading = true;
      console.log("wwwwww ---  88888");
      this.pageNumber++;
      let params = {
        owner: this.$store.state.did,
        //owner:'iaa1dqfet703fj838m9u3yq68h2rczt8fr3e57agal',
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      let res = await api.home.joinListByOwner(params);
      
      this.totalPage = res.data.totalPage;
      res.data.list.forEach(async v => {
                   this.list.push({
                       id: v.id,
                       activityName: v.activityName,
                       luck:v.luck,
                       prizePool: v.prizePool,
                       joinNumber:v.joinNumber,
                       endTime: this.timestampToTime(v.endTime),
             txid:v.txid,
             coinDenom:v.coinDenom
                   });
              })
      this.isShowLoading = false;
      this.isLoading = false
      if ((this.pageNumber== 1 || this.pageNumber== 0) && res.data.list.length == 0) {
            this.empty = true;
          } else {
            this.empty = false;
          }
    },
    timestampToTime(time) {
	  let date = new Date(time)
	  let Y = date.getFullYear() + '-';
	  let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
	  let D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + ' ';
	  let h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':';
	  let m = (date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes()) + ':';
	  let s = (date.getSeconds() < 10 ? '0'+date.getSeconds() : date.getSeconds());
	  let strDate = Y+M+D+h+m+s;
	  return strDate;
  },
  shareBtn(id) {
    
      //this.openShare = !this.openShare;
    this.share_id != 0 ? (this.share_id!=id?this.share_id=id:this.share_id=0) : this.share_id = id;
      
    },
    toCopyLink() {
      // http://web.upticknft.com:8084/#/saledetail?id=7666
      var input = document.createElement("input");
      input.value =
        process.env.VUE_APP_BASE_URL +
        `#/saledetail?id=${this.$route.query.id}`;

      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      this.isCopy = true;
      setTimeout(() => (this.isCopy = false), 100);

      //xxl TODO need to add tip
    },

    toTweet() {
      // alert("abc ...");
      var meta = document.createElement("meta");
      //meta.setAttribute('property', 'og:image');
      meta.setAttribute("name", "twitter:image:src");
      meta.setAttribute(
        "content",
        "https://cdn.cms-twdigitalassets.com/content/dam/developer-twitter/redesign-2021-images/og-social-card/devwebsite_card_tn.jpg.twimg.768.jpg"
      );
      document.getElementsByTagName("head")[0].appendChild(meta);

      //window.open("http://twitter.com/share?text=Im Sharing on Twitter&url=http://stackoverflow.com/users/2943186/youssef-subehi&hashtags=stackoverflow,example,youssefusf")
      window.open(
        "https://twitter.com/intent/tweet?text=Hello%20world&url=http://graphics8.nytimes.com/images/2012/02/19/us/19whitney-span/19whitney-span-articleLarge.jpg"
      );
      //window.open("https://twitter.com/intent/tweet?original_referer=https%3A%2F%2Fdeveloper.twitter.com%2F&amp;ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Eshare%7Ctwgr%5E&amp;text=Guides%20%7C%20Docs%20%7C%20Twitter%20Developer%20Platform&amp;url=https%3A%2F%2Fdeveloper.twitter.com%2Fen%2Fdocs%2Ftwitter-for-websites%2Ftweet-button%2Foverview")
    },
     async onScroll(e) {
       
         const {scrollTop, clientHeight, scrollHeight} =
           e.target.scrollingElement;
           console.log(scrollTop,clientHeight,scrollHeight,this.totalPage,this.pageNumber)
         if (scrollTop + clientHeight === scrollHeight && (this.totalPage > this.pageNumber)) {
            
            await this.getlist();
         }  
       
      },
      toLotteries(id){
        console.log("wxl --- toLotteries")
        console.log(id)
      }
  },
};
</script>

<style lang="scss" scoped>
.contain {
   .empty {
	  width: 100%;
	  height: 500px;
	  display: flex;
	  justify-content: center;
	  align-items: center;
  }
   
  .list {
    max-width: 1217px;
    height: 101px;
    background-image: linear-gradient(#ffffff, #ffffff),
      linear-gradient(#6f58d9, #6f58d9);
    background-blend-mode: normal, normal;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
    padding: 24px 31px 29px;
    position:relative;
    .price {
      // max-width:468px;
      .pricetit {
        font-family:Helvetica;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #270645;
        margin-bottom: 0px;
      }
      img {
        width: 21px;
        height: 21px;
        display: block;
        margin-top: 4px;
        margin-right: 15px;
      }
      .pricenum {
       font-family:Helvetica;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #270645;
      }
    }
    .num {
      // max-width:190px;
      margin: 17px 0;
       cursor: pointer;
      position:relative;
      .pool {
        margin-left: -44px;
        position: absolute;
        margin-top: -4px;
      }
    }
    .time {
      margin: -5px 0;
    }
    .link {
      position:relative;
      margin: 15px 0;
       
      
    }
   .share {
          width: 166px;
          height: 161px;
          background-image: linear-gradient(#ffffff, #ffffff),
            linear-gradient(#6f58d9, #6f58d9);
          background-blend-mode: normal, normal;
          box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
          border-radius: 5px;
          position: absolute;
          right: 31px;
          top: 63px;
          z-index: 20000;

          img,
          .icon {
            display: inline-block;
            width: 25px;
            height: 25px;
          }

          span {
           font-family:Helvetica;
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;
          }
        }
  }
}
</style>
export function share(type,imageUrl,contractId,linkUrl) {

  let url = window.document.URL;
  if(contractId!=undefined&&contractId!=''){
	  url=url.replace("carddetail","saledetail")
  }
  if(linkUrl!=undefined&&linkUrl!=''){
  	  url=linkUrl
  }
  
  let enCodeUrl = encodeURIComponent(url);

  console.log("xxl imageUrl : " + imageUrl);

  switch (type) {
    case "copyLink":
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(url);
      } else {
        let textArea = document.createElement("textarea");
        textArea.value = url;
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        new Promise((res, rej) => {
          document.execCommand("copy") ? res() : rej();
          textArea.remove();
        });
      }
      //xxl TODO
      //need prompt
      this.$toastFn(this.$t("CopySuccess"), {
        color: 'success'
      });

      break;
    case "twitter":
      {

        createTwitterMeta(imageUrl);
        const a = document.createElement("a");
        a.style.display = "none";
        a.target = "_blank";
        a.href = `https://twitter.com/intent/tweet?url=${enCodeUrl}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

      }
      break;
    case "facebook":
      {
        createFacebookMeta(imageUrl);
        const a = document.createElement("a");
        a.style.display = "none";
        a.target = "_blank";
        a.href = `https://www.facebook.com/sharer/sharer.php?u=${enCodeUrl}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
      break;
    default:
      break;
  }
}


export function createTwitterMeta(imgUrl){

    // let meta0 = document.createElement('meta');
    //  meta0.setAttribute('name','twitter:card');
    //  meta0.setAttribute('content', 'summary_large_image');
    //  document.getElementsByTagName('head')[0].appendChild(meta0);

    //  let meta1 = document.createElement('meta');
    //  meta1.setAttribute('name','twitter:image');
    //  meta1.setAttribute('content', imgUrl);
    //  document.getElementsByTagName('head')[0].appendChild(meta1);
	
	let html=document.getElementsByTagName('head')[0].innerHTML;
	html=html.replace(new RegExp("https://web.upticknft.com/logo.jpg","gm"),imgUrl);
	
	document.getElementsByTagName('head')[0].innerHTML=html;


}

function createFacebookMeta(imgUrl){

  let meta0 = document.createElement('meta');
  meta0.setAttribute('name','og:image');
  meta0.setAttribute('content', imgUrl);
  document.getElementsByTagName('head')[0].appendChild(meta0);


}